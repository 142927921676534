import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      appFrame: false,
      navBar: false,
      basePath: true,
    },
    component: Login,
   
  },
  {
    path: '/cities',
    name: 'cities',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      title:'Ciudades',
      allowedRoles: ["sudo",],
    },
   

    component: () => import('../views/cities.vue')
  },
  {
    path: '/sections',
    name: 'sections',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      title:'Secciones',
      allowedRoles: ["sudo"],
    },
   

    component: () => import('../views/sudo/sections')
  },
  {
    path: '/business',
    name: 'business',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      title:'Business',
      allowedRoles: ["sudo",],
    },
    

    component: () => import('../views/business.vue')
  },
  {
    path: '/coupons',
    name: 'coupons',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo",],
    },
  
    component: () => import('../views/coupons.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo",],
    },
   
    component: () => import('../views/sudo/user-lists/users.vue')
  },
  {
    path: '/usersPending',
    name: 'usersPending',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo",],
    },
   
    component: () => import('../views/sudo/user-lists/usersPending.vue')
  },
  {
    path: '/businessPending',
    name: 'businessPending',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo",],
    },
   
    component: () => import('../views/businessPending.vue')
  },
  {
    path: '/salesCoupons',
    name: 'salesCoupons',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo","admin"],
    },
  
    component: () => import('../views/salesCoupons.vue')
  },
  {
    path: '/redemption',
    name: 'redemption',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["admin"],
    },

    component: () => import('../views/redemption.vue')
  },
  {
    path: '/usersAdmin',
    name: 'usersAdmin',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo",],
    },
    component: () => import('../views/usersAdmin.vue')
  },
  {
    path: '/reporteria',
    name: 'reporteria',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo",],
    },
    component: () => import('../views/sudo/reporteria.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo",],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Categories.vue')
  },
  {
    path: '/launchColab',
    name: 'launchColab',
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo",],
    },
    component: () => import('../views/launchColab.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
