<template>
  <div class="logins">
    <img width="180px" class="mb-12" style="z-index: 100" :src="logo" alt="" srcset="" />
    <div class="wrap">
      <v-card class="wrap-login">
        <v-card-title class="font-weight-medium " style="font-size: 30px !important">
          <span class="text-center">
            <span class="font-weight-light ">Sistema</span>
            Administrativo
          </span>
        </v-card-title>
       
        <v-card-text>
          <p>Ingresa tu dirección de correo electrónico y contraseña para
            ingresar.</p>
          <v-form style="width: 100%" ref="form" v-model="valid" lazy-validation @keyup.enter.native="onNext">
            <v-text-field label="Correo Electrónico" v-model="email" :rules="[rules.required, rules.email]"
              ref="mailAddress" type="email" hint="correo@servidor.tldn" autocomplete="email" filled :disabled="loading"
              style="border-radius: 10px" rounded dense></v-text-field>

            <v-text-field v-model="password" label="Contraseña" hint="la contraseña debe tener más de 8 carácteres"
              autocomplete="current-password" :append-icon="show1 ? 'fa-eye' : 'fa-eye-slash'"
              :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" filled :disabled="loading"
              rounded style="border-radius: 10px !important" dense @click:append="show1 = !show1"></v-text-field>
            <div style="display: flex; justify-content: center; margin-bottom: 20px" id="recaptcha-container"></div>

            <!-- id="sign-in-button" -->

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" rounded block :loading="loading" large @click="onNext" :elevation="0" :minHeight="50"
            style="border-radius: 10px !important">Ingresar</v-btn>
        </v-card-actions>
      </v-card>

      <v-btn text dark @click="passReset"> ¿Olvidaste tu contraseña? </v-btn>
      <br />
    </div>

    <v-dialog persistent v-if="otpDialog" v-model="otpDialog" max-width="500px">
      <otp :auth="authObject" ref="code" @cancel="otpDialog = false" @success="otpConfirmed" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import otp from "../views/sudo/settings/insertCode.vue";
export default {
  name: "login",
  components: {
    otp,
  },
  data() {
    return {
      loading: false,
      email: "",
      logo: require("@/assets/SVG/Logo.svg"),
      password: "",
      valid: true,
      show1: false,
      otpDialog: false,
      authObject: null,
      verificationId: null,
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        // emailMatch: () => "The email and password you entered don't match",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
    };
  },
  methods: {
    ...mapActions(["Alert_"]),
    async mfaLogin() {
      var phoneInfoOptions = {
        multiFactorHint: this.authObject.hints[0],
        session: this.authObject.session,
      };

      try {
        var phoneAuthProvider = new fb.auth.PhoneAuthProvider();

        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        this.otpDialog = true;
      } catch (error) {
        recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });
      }
    },
    async otpConfirmed(otp) {
      try {
        var cred = await fb.auth.PhoneAuthProvider.credential(
          this.verificationId,
          otp
        );

        var multiFactorAssertion =
          await fb.auth.PhoneMultiFactorGenerator.assertion(cred);

        await this.authObject.resolveSignIn(multiFactorAssertion);
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-verification-code":
            this.$refs.code.error();

            break;

          default:
            break;
        }
      }
    },
    passReset() {
      let emailAddress = this.email;
      fb.auth().languageCode = "es";

      if (this.$refs.mailAddress.validate()) {
        fb.auth()
          .sendPasswordResetEmail(emailAddress)
          .then(() => {
            // Email sent.
            this.showMessage(
              "Correo enviado, revisa tu bandeja de entrada",
              "info"
            );
            console.log("email sent");
          })
          .catch((error) => {
            console.log(error);
            this.showMessage(
              "Error al enviar correo, verifica tu conexión a internet"
            );

            // An error happened.
          });
      } else {
        this.showMessage(
          "Ingresa tu dirección de correo electrónico para enviarte un enlace de reinicio de contraseña"
        );
      }
    },
    showMessage(msg, type) {
      this.Alert_({
        text: msg,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: type ? type : "error",
      });
      this.loading = false;
      // grecaptcha.reset(window.recaptchaWidgetId);
    },
    onNext() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        // fb.auth()
        //   .setPersistence(fb.auth.Auth.Persistence.SESSION)
        //   .then(() => {

        fb.auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            // Handle Errors here.
            console.log(error);
            switch (error.code) {
              case "auth/multi-factor-auth-required":
                this.authObject = error.resolver;
                this.mfaLogin();
                break;

              case "auth/invalid-email":
                this.showMessage(
                  "Por favor ingresa la dirección de correo correctamente"
                );
                break;

              case "auth/user-not-found":
                this.showMessage(
                  "No existen usuarios con este correo. Verifica tu dirección o ponte en contacto con el administrador."
                );

                break;

              case "auth/wrong-password":
                this.showMessage("Contraseña incorrecta");

                break;

              case "auth/argument-error":
                this.showMessage(
                  "El correo y la contraseña deben ser campos válidos"
                );
                break;

              default:
                this.showMessage(error.message);

                break;
            }
            // ...
          });
        // });
      } else {
        this.showMessage("Llena todos los campos para continuar");
      }
    },
  },
  mounted() {
    // window.recaptchaVerifier = new fb.auth.RecaptchaVerifier("sign-in-button", {
    //   size: "invisible",
    //   callback: async () => {
    //     this.onNext();
    //   },
    // });
    // recaptchaVerifier.render().then(function (widgetId) {
    //   window.recaptchaWidgetId = widgetId;
    // });
  },
};
</script>
<style lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";

.logins {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  background-image: radial-gradient(circle at 0% 0, #1e2428, #0a0c0d);
  flex-direction: column;
  // background-image: url('../assets/fondo.avif');


  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .wrap {
    text-align: center;
    max-width: 400px;
    // #dbe400
    .wrap-login {
     
      // background-color: #212031 !important;
      border-radius: 16px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: stretch;
      box-sizing: border-box;
      margin-bottom: 20px;

    }
  }
}
</style>
